<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>管理员管理 / 修改</span>
      </div>
      <div>
        <el-form :inline="false" class="form" style="text-align: left;" label-width="100px" :model="item" :rules="rules" ref="form">
          <el-form-item label="账号" class="form-item" prop="username">
            <el-input v-model="item.username"  size="small" disabled></el-input>
          </el-form-item>

          <el-form-item label="密码" class="form-item" prop="password">
            <el-input :type="passwordType" v-model="item.password" placeholder="请填写密码" size="small" auto-complete="off">
              <i class="el-icon-view el-input__icon"
                 slot="suffix"
                 @click="handleIconClick">
              </i>
            </el-input>
          </el-form-item>

          <el-form-item label="商户" class="form-item">
            <el-select v-model="item.merchant_id"  clearable filterable >
              <el-option v-for="item in merchant_list" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>

<!--          <el-form-item label="角色" class="form-item">
            <el-select v-model="item.role_id"  clearable filterable >
              <el-option v-for="item in role_list" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>-->

          <el-form-item style="text-align:left;">
            <el-button type="button" class="el-button--primary" @click="submit" style="margin-right:20px;">确定
            </el-button>
            <router-link :to="{path: `/admin/admin_manage/index`}">
              <el-button type="button" class="el-button--default">取消</el-button>
            </router-link>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "new.vue",
  data() {
    return {
      fullscreenLoading: false,
      passwordType: 'password',
      item: {
        id:null,
        username:null,
        role_id:null,
        merchant_id:null,
        password:null,
      },
      merchant_list:[],
      rules:{
        username: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
      },
      role_list:[]
    }
  },
  mounted() {
    this.item.id = this.$route.params.id
    this.getMerchantList();
    //this.getRoleList();
    this.getItem()
  },
  methods: {
    handleIconClick() {
      return this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    submit() {
      let _this = this;
      let params = {
        id:_this.item.id,
        username: _this.item.username,
        role_id:_this.item.role_id,
        merchant_id: _this.item.merchant_id,
        password:_this.item.password?_this.$md5(_this.item.password):null
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.fullscreenLoading = true
          adminHttp.post('/backend/admin/editAdmin', params).then(result => {
            if (result.code === 200) {
              _this.$message.success('修改成功')
              _this.$router.push({path: '/admin/admin_manage/index'})
            } else {
              _this.$message.error(result.message || '修改失败')
            }
          }).catch(error=>{
            _this.$message.error('请求失败')
          }).finally(()=>{
            _this.fullscreenLoading = false
          })
        }
      })
    },getItem() {
      let _this = this
      let params = {
        id: _this.item.id
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/admin/querySingleAdmin', params).then(result => {
        if (result.code === 200) {
          _this.item = {
            id: result.data.id,
            username: result.data.username,
            role_id: result.data.roleId,
            merchant_id: result.data.merchantId,
          }
        }else{
          _this.$message.error(result.message);
        }
      }).catch(error=>{
        _this.$message.error('请求失败')
      }).finally(()=>{
        _this.fullscreenLoading = false
      })
    },getMerchantList(){
      let _this = this
      _this.fullscreenLoading = true
      let params={
        page: 0,
        page_size: 10000,
      }
      adminHttp.post('/backend/merchant/queryMerchantList',params).then(result => {
        if (result && result.code === 200) {
          if(result.data!==null){
            let data = result.data.content;
            for(let i=0;i<data.length;i++){
                let obj ={
                  label:data[i].merName,
                  value:data[i].id
                }
                _this.merchant_list.push(obj);
            }
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }/*,getRoleList(){
      let _this = this
      _this.fullscreenLoading = true
      let params={
        page: 0,
        page_size: 100,
      }
      adminHttp.post('/backend/role/queryRoleList',params).then(result => {
        if (result && result.code === 200) {
          if(result.data!==null){
            let data = result.data.content;
            for(let i=0;i<data.length;i++){
              let obj ={
                label:data[i].name,
                value:data[i].id
              }
              _this.role_list.push(obj);
            }
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }*/
  }
}
</script>

<style scoped>

</style>